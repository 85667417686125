import BaseModule from './BaseModule.js';

class Tier extends BaseModule {
  async paginate({ page, per_page, term }) {
    return await this.get(`back-office/tiers`, { params: { page, per_page, term } });
  }

  async fetch(id) {
    return await this.get(`/back-office/tiers/${id}`);
  }

  async create(tier) {
    return await this.post(`/back-office/tiers`, tier);
  }

  async update(tier) {
    return await this.put(`/back-office/tiers/${tier.id}`, tier);
  }

  async remove(id) {
    return await this.delete(`/back-office/tiers/${id}`);
  }
}

export default Tier;
