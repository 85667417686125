import React from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilAddressBook,
  cilBriefcase,
  cilDataTransferDown,
  cilDescription,
  cilDollar,
  cilLan,
  cilPeople,
  cilShieldAlt,
  cilSpeedometer,
  cilSpreadsheet,
  cilWallet,
} from '@coreui/icons';
import { CNavGroup, CNavItem } from '@coreui/react';

const RoutesNav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    component: CNavGroup,
    name: 'Orders',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    items: [
      {
        component: CNavItem,
        name: 'List',
        to: '/orders/list-orders',
        roles: ['ADMIN', 'USER', 'ANALYZE'],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Users',
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER'],
    items: [
      {
        component: CNavItem,
        name: 'List',
        to: '/users/list-users',
        roles: ['ADMIN', 'USER'],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Currencies',
    icon: <CIcon icon={cilDollar} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    items: [
      {
        component: CNavItem,
        name: 'List',
        to: '/currencies/list-currencies',
        roles: ['ADMIN', 'USER', 'ANALYZE'],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Customers',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    items: [
      {
        component: CNavItem,
        name: 'List',
        to: '/customers/list-customers',
        roles: ['ADMIN', 'USER', 'ANALYZE'],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Wallets',
    icon: <CIcon icon={cilWallet} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    items: [
      {
        component: CNavItem,
        name: 'List',
        to: '/wallets/list-wallets',
        roles: ['ADMIN', 'USER', 'ANALYZE'],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Networks',
    icon: <CIcon icon={cilLan} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    items: [
      {
        component: CNavItem,
        name: 'List',
        to: '/networks/list-networks',
        roles: ['ADMIN', 'USER', 'ANALYZE'],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Pix Cash Ins',
    icon: <CIcon icon={cilDataTransferDown} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    items: [
      {
        component: CNavItem,
        name: 'List',
        to: '/pix-cash-ins/list-pix-cash-ins',
        roles: ['ADMIN', 'USER', 'ANALYZE'],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Payers',
    icon: <CIcon icon={cilBriefcase} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    items: [
      {
        component: CNavItem,
        name: 'List',
        to: '/payers/list-payers',
        roles: ['ADMIN', 'USER', 'ANALYZE'],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Tiers',
    icon: <CIcon icon={cilShieldAlt} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    items: [
      {
        component: CNavItem,
        name: 'List',
        to: '/tiers/list-tiers',
        roles: ['ADMIN', 'USER', 'ANALYZE'],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Withdrawals',
    icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
    roles: ['ADMIN', 'USER', 'ANALYZE'],
    items: [
      {
        component: CNavItem,
        name: 'List',
        to: '/withdrawals/list-withdrawals',
        roles: ['ADMIN', 'USER', 'ANALYZE'],
      },
    ],
  },
];

export default RoutesNav;
