import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './resources';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async (callback) => {
    let language = 'en';
    const storageData = localStorage.getItem('persist:criptou');
    if (storageData) {
      const parsedStorageData = JSON.parse(storageData);
      const uiData = JSON.parse(parsedStorageData.ui);
      if (uiData && uiData.ui && uiData.ui.language) {
        language = uiData.ui.language;
      }
    }
    const persistedLocale = language;
    callback(persistedLocale);
  },
  init: () => {},
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources: resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
