import BaseModule from './BaseModule.js';

class Customer extends BaseModule {
  async paginate({ page, per_page, term, status }) {
    return await this.get(`back-office/customers`, {
      params: { page, per_page, term, status },
    });
  }

  async fetch(id) {
    return await this.get(`/back-office/customers/${id}`);
  }

  async update(customer) {
    return await this.patch(`/back-office/customers/${customer.id}`, customer);
  }

  async createApiCredential(credential) {
    return await this.post(`/back-office/customers/${credential.id}/api_credentials`, credential);
  }

  async removeCredential(customer_id, api_credential_id) {
    return await this.delete(
      `/back-office/customers/${customer_id}/api_credentials/${api_credential_id}/`,
    );
  }
}

export default Customer;
