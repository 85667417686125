import BaseModule from './BaseModule.js';

class Network extends BaseModule {
  async paginate({ page, per_page, role, term }) {
    return await this.get(`back-office/networks`, { params: { page, per_page, role, term } });
  }

  async create(network) {
    return await this.post(`/back-office/networks`, network);
  }

  async update(network) {
    return await this.put(`/back-office/networks/${network.id}`, network);
  }
}

export default Network;
