import BaseModule from './BaseModule';

class Auth extends BaseModule {
  csrfToken() {
    return this.get('/sanctum/csrf-cookie');
  }

  login(email, password) {
    return this.post('/back-office/auth', {
      email,
      password,
    });
  }

  user() {
    return this.get('/back-office/user');
  }

  logout() {
    return this.post('/back-office/logout');
  }
}

export default Auth;
