import BaseModule from './BaseModule.js';

class PixCashIns extends BaseModule {
  async paginate({ page, per_page, term }) {
    return await this.get(`back-office/pix_cash_ins`, { params: { page, per_page, term } });
  }

  async fetch(id) {
    return await this.get(`back-office/pix_cash_ins/${id}`);
  }

  async refund(pix) {
    return await this.put(`/back-office/pix_cash_ins/${pix.id}/refund`);
  }
}

export default PixCashIns;
