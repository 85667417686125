import BaseModule from './BaseModule.js';

class Orders extends BaseModule {
  async paginate({ page, per_page, term, status }) {
    return await this.get(`back-office/orders`, { params: { page, per_page, term, status } });
  }

  async fetch(id) {
    return await this.get(`back-office/orders/${id}`);
  }

  async accept(id) {
    return await this.post(`back-office/orders/${id}/accept`);
  }

  async reject(id) {
    return await this.post(`back-office/orders/${id}/reject`);
  }

  async refund(id) {
    return await this.post(`back-office/orders/${id}/refund`);
  }

  async retryWithdrawal(id) {
    return await this.post(`back-office/orders/${id}/retry_withdrawal`);
  }
}

export default Orders;
