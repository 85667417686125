import BaseModule from './BaseModule.js';

class Event extends BaseModule {
  async paginate({ page, per_page, owner_type, owner_id }) {
    return await this.get(`back-office/event_logs`, {
      params: { page, per_page, owner_type, owner_id },
    });
  }

  async create(event) {
    return await this.post(`/back-office/event_logs`, {
      owner_type: event.owner_type,
      owner_id: event.owner_id,
      notes: event.notes,
    });
  }
}

export default Event;
