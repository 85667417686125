import createReducer from '../store/createReducer';

const initial_state = {
  ui: {
    sidebarShow: true,
    sidebarUnfoldable: true,
    theme: 'dark',
    language: 'pt_br',
  },
  notification: {
    title: '',
    type: '',
    message: '',
  },
};

const [LayoutReducer, actions] = createReducer('LAYOUT', initial_state);

export const setUi = actions.setUi;
export const setNotification = actions.setNotification;

export default LayoutReducer;
