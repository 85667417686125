import React from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../route/Routes';
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import { useTranslation } from 'react-i18next';

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname;
  const { t } = useTranslation();

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname);
    return currentRoute && currentRoute.name;
  };

  const formatRouteName = (name) => {
    return t(`menuItems.${name.toLowerCase().replace(/\s+/g, '-')}`, { count: 2 });
  };

  const getBreadcrumbs = (location) => {
    const breadcrumbs = [];
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      const routeName = getRouteName(currentPathname, routes);
      const formattedRouteName = routeName && formatRouteName(routeName);
      formattedRouteName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: formattedRouteName, // Utilizando o nome tratado e traduzido da rota
          active: index + 1 === array.length ? true : false,
        });
      return currentPathname;
    });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb className="my-0">
      <CBreadcrumbItem href="/dashboard">Home</CBreadcrumbItem>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
            key={index}
          >
            {breadcrumb.name}
          </CBreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
};

export default React.memo(AppBreadcrumb);
