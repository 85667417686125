import BaseModule from './BaseModule.js';

class Currency extends BaseModule {
  async paginate({ page, per_page, term }) {
    return await this.get(`back-office/currencies`, { params: { page, per_page, term } });
  }

  async create(currency) {
    return await this.post(`/back-office/currencies`, currency);
  }

  async update(currency) {
    return await this.put(`/back-office/currencies/${currency.id}`, currency);
  }
}

export default Currency;
