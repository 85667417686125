import { combineReducers } from 'redux';
import UserReducer from '../reducers/UserReducer';
import AuthReducer from '../reducers/AuthReducer';
import UiReducer from '../reducers/LayoutReducer';

const reducers = combineReducers({
  user: UserReducer,
  auth: AuthReducer,
  ui: UiReducer,
});

export default reducers;
