export const sygnet = [
  '90 50',
  `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
  <g style="fill: currentColor">
  
  <g><path style="opacity:0.525" fill="#49D3CC" d="M 39.5,17.5 C 57.8333,17.5 76.1667,17.5 94.5,17.5C 94.1394,21.9155 92.8061,25.9155 90.5,29.5C 90.9054,26.0135 91.9054,22.6801 93.5,19.5C 84.1904,18.5017 74.8571,18.1684 65.5,18.5C 56.6506,18.8264 47.9839,18.493 39.5,17.5 Z"/></g>
  <g><path style="opacity:1" fill="#49D3CC" d="M 13.5,17.5 C 20.6552,18.3288 27.9885,18.8288 35.5,19C 37.1667,19.3333 38.8333,19.6667 40.5,20C 37.5184,20.498 34.5184,20.6646 31.5,20.5C 31.3345,24.5138 31.5012,28.5138 32,32.5C 32.5,32 33,31.5 33.5,31C 41.4931,30.5003 49.4931,30.3336 57.5,30.5C 68.2581,31.1284 78.7581,30.4618 89,28.5C 89.4828,29.448 89.6495,30.448 89.5,31.5C 89.5,32.1667 89.1667,32.5 88.5,32.5C 70.1546,32.1671 51.8212,32.5004 33.5,33.5C 33.6236,34.1067 33.9569,34.44 34.5,34.5C 35.5,34.8333 36.1667,35.5 36.5,36.5C 36.9528,37.4584 37.6195,38.2917 38.5,39C 36.6595,43.0209 35.3262,47.1876 34.5,51.5C 34.5,51.8333 34.5,52.1667 34.5,52.5C 32.7329,54.7916 31.7329,57.4582 31.5,60.5C 30.596,61.209 30.2627,62.209 30.5,63.5C 46.5,63.5 62.5,63.5 78.5,63.5C 77.8902,64.391 77.5569,65.391 77.5,66.5C 62.4082,64.5405 47.0748,63.8738 31.5,64.5C 31.5,68.1667 31.5,71.8333 31.5,75.5C 45.5,75.5 59.5,75.5 73.5,75.5C 73.1667,76.1667 72.8333,76.8333 72.5,77.5C 52.8305,77.6666 33.1638,77.5 13.5,77C 10.6122,74.0686 7.61218,71.2353 4.5,68.5C 4.70931,67.914 5.04265,67.414 5.5,67C 3.86586,66.5065 2.19919,66.3398 0.5,66.5C 0.5,65.5 0.5,64.5 0.5,63.5C 3.77659,63.7839 6.94325,63.4505 10,62.5C 14.4015,49.7954 18.9015,37.1288 23.5,24.5C 18.1827,22.9367 12.8493,21.4367 7.5,20C 6.16667,19.3333 6.16667,18.6667 7.5,18C 9.4727,17.5045 11.4727,17.3379 13.5,17.5 Z"/></g>
  <g><path style="opacity:1" fill="#49D3CC" d="M 13.5,17.5 C 22.1667,17.5 30.8333,17.5 39.5,17.5C 47.9839,18.493 56.6506,18.8264 65.5,18.5C 66.6085,19.29 67.9419,19.79 69.5,20C 65.5138,20.4988 61.5138,20.6655 57.5,20.5C 57.5,23.8333 57.5,27.1667 57.5,30.5C 49.4931,30.3336 41.4931,30.5003 33.5,31C 33,31.5 32.5,32 32,32.5C 31.5012,28.5138 31.3345,24.5138 31.5,20.5C 34.5184,20.6646 37.5184,20.498 40.5,20C 38.8333,19.6667 37.1667,19.3333 35.5,19C 27.9885,18.8288 20.6552,18.3288 13.5,17.5 Z"/></g>
  <g><path style="opacity:1" fill="#49D3CC" d="M 65.5,18.5 C 74.8571,18.1684 84.1904,18.5017 93.5,19.5C 91.9054,22.6801 90.9054,26.0135 90.5,29.5C 90.6716,30.4916 90.3382,31.1583 89.5,31.5C 89.6495,30.448 89.4828,29.448 89,28.5C 78.7581,30.4618 68.2581,31.1284 57.5,30.5C 57.5,27.1667 57.5,23.8333 57.5,20.5C 61.5138,20.6655 65.5138,20.4988 69.5,20C 67.9419,19.79 66.6085,19.29 65.5,18.5 Z"/></g>
  <g><path style="opacity:0.133" fill="#49D3CC" d="M 88.5,32.5 C 70.5,33.1667 52.5,33.8333 34.5,34.5C 33.9569,34.44 33.6236,34.1067 33.5,33.5C 51.8212,32.5004 70.1546,32.1671 88.5,32.5 Z"/></g>
  <g><path style="opacity:0.58" fill="#49D3CC" d="M 36.5,36.5 C 38.2517,36.4176 39.2517,37.2509 39.5,39C 38.2957,43.466 36.6291,47.6326 34.5,51.5C 35.3262,47.1876 36.6595,43.0209 38.5,39C 37.6195,38.2917 36.9528,37.4584 36.5,36.5 Z"/></g>
  <g><path style="opacity:0.651" fill="#49D3CC" d="M 34.5,52.5 C 34.2671,55.5418 33.2671,58.2084 31.5,60.5C 31.7329,57.4582 32.7329,54.7916 34.5,52.5 Z"/></g>
  <g><path style="opacity:0.009" fill="#49D3CC" d="M 44.5,61.5 C 56.5185,61.1677 68.5185,61.501 80.5,62.5C 78.5687,67.1271 76.7353,71.7938 75,76.5C 74.3292,77.2524 73.4959,77.5858 72.5,77.5C 72.8333,76.8333 73.1667,76.1667 73.5,75.5C 75.2049,72.7391 76.5383,69.7391 77.5,66.5C 77.5569,65.391 77.8902,64.391 78.5,63.5C 67.1667,62.8333 55.8333,62.1667 44.5,61.5 Z"/></g>
  <g><path style="opacity:0.89" fill="#49D3CC" d="M 31.5,60.5 C 31.369,61.2389 31.5357,61.9056 32,62.5C 36.0659,61.5363 40.2325,61.203 44.5,61.5C 55.8333,62.1667 67.1667,62.8333 78.5,63.5C 62.5,63.5 46.5,63.5 30.5,63.5C 30.2627,62.209 30.596,61.209 31.5,60.5 Z"/></g>
  <g><path style="opacity:1" fill="#49D3CC" d="M 77.5,66.5 C 76.5383,69.7391 75.2049,72.7391 73.5,75.5C 59.5,75.5 45.5,75.5 31.5,75.5C 31.5,71.8333 31.5,68.1667 31.5,64.5C 47.0748,63.8738 62.4082,64.5405 77.5,66.5 Z"/></g>
  
  </g>
  </svg>
  `,
];
