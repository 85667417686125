import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CSpinner } from '@coreui/react';
import { useSelector } from 'react-redux';
import routes from '../route/Routes';
import Page403 from 'src/views/pages/Page403';
import { ADMIN_ROLE, ANALYZE_ROLE, USER_ROLE } from 'src/utils/constants';

const AppContent = () => {
  const { profile } = useSelector((state) => state.user);
  const roleConstants = [ADMIN_ROLE, USER_ROLE, ANALYZE_ROLE];

  return (
    <div className="px-4">
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes.map((route, idx) => {
            const requiredRoles = route.role || [];
            const canAccessRoute = requiredRoles.some((role) =>
              roleConstants.some((constantRole) => constantRole.includes(role)),
            );
            const userHasUserRole = profile && profile.role && requiredRoles.includes(profile.role);

            if (route.element) {
              if (canAccessRoute && userHasUserRole) {
                return (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                );
              } else {
                return <Route key={idx} path={route.path} element={<Page403 />} />;
              }
            }
            return null;
          })}
        </Routes>
      </Suspense>
    </div>
  );
};

export default React.memo(AppContent);
