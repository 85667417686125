import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useColorModes } from '@coreui/react';
import AppRoutes from './route/AppRoutes';
import { cleanTheme } from './utils/UtilsFunc';
import './scss/style.scss';

const App = () => {
  const { setColorMode } = useColorModes('coreui-theme');
  const stateUI = useSelector((state) => state.ui.ui);
  const { theme } = stateUI;

  useEffect(() => {
    cleanTheme();
    setColorMode(theme);
  }, [setColorMode, theme]);

  return <AppRoutes />;
};

export default App;
