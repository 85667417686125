import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CCloseButton,
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarHeader,
  CSidebarNav,
  CSidebarToggler,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { AppSidebarNav } from './AppSidebarNav';
import { logo_light, logo_dark } from 'src/assets/brand/logo';
import { sygnet } from 'src/assets/brand/sygnet';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { setUi } from 'src/reducers/LayoutReducer';
import RoutesNav from 'src/route/RoutesNav';
import { useTranslation } from 'react-i18next';

const AppSidebar = () => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const ui = useSelector((state) => state.ui.ui);
  const { sidebarShow, sidebarUnfoldable, theme } = ui;

  const formatRouteName = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-');
  };

  const translateRoutes = () => {
    const { t } = useTranslation();

    return RoutesNav.map((route) => ({
      ...route,
      name: t(`menuItems.${formatRouteName(route.name)}`, { count: 2 }),
      items: route.items
        ? route.items.map((item) => ({
            ...item,
            name: t(`menuItems.${formatRouteName(item.name)}`),
            to: item.to ? formatRouteName(item.to) : null,
          }))
        : null,
    }));
  };

  const translatedRoutes = translateRoutes();

  const filterNavByRole = (navItems, userRoles) => {
    return navItems.filter((item) => {
      if (item.roles && item.roles.length > 0) {
        return item.roles.some((role) => userRoles.includes(role));
      }
      return true;
    });
  };

  const filteredNav = filterNavByRole(
    translatedRoutes,
    profile && profile.role ? [profile.role] : [],
  );

  return (
    <CSidebar
      className="border-end"
      colorScheme={theme}
      position="fixed"
      unfoldable={sidebarUnfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(setUi({ ...ui, sidebarShow: visible }));
      }}
    >
      <CSidebarHeader className="border-bottom">
        <CSidebarBrand to="/dashboard">
          <CIcon
            customClassName="sidebar-brand-full"
            icon={theme === 'dark' ? logo_light : logo_dark}
            height={32}
          />
          <CIcon customClassName="sidebar-brand-narrow" icon={sygnet} height={32} />
        </CSidebarBrand>
        <CCloseButton
          className="d-lg-none"
          dark
          onClick={() => dispatch(setUi({ ...ui, sidebarShow: false }))}
        />
      </CSidebarHeader>

      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={filteredNav} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarFooter className="border-top d-none d-lg-flex">
        <CSidebarToggler
          onClick={() => dispatch(setUi({ ...ui, sidebarUnfoldable: !sidebarUnfoldable }))}
        />
      </CSidebarFooter>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
