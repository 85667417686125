class BaseModule {
  constructor({ axios }) {
    this.axios = axios;
  }

  get(endpoint, config) {
    return this.request('get', endpoint, config);
  }

  post(endpoint, data, config) {
    return this.request('post', endpoint, data, config);
  }

  put(endpoint, data, config) {
    return this.request('put', endpoint, data, config);
  }

  patch(endpoint, data, config) {
    return this.request('patch', endpoint, data, config);
  }

  delete(endpoint, config) {
    return this.request('delete', endpoint, config);
  }

  async request(method, endpoint, data, config) {
    let response = await this.axios[method](endpoint, data, config);
    return response.data;
  }
}

export default BaseModule;
