import React, { useState } from 'react';
import api from '../../api';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setProfile } from '../../reducers/UserReducer';
import { setAuthenticated } from '../../reducers/AuthReducer';
import { Link } from 'react-router-dom';
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilLockLocked, cilUser } from '@coreui/icons';
import { logo_light, logo_dark } from 'src/assets/brand/logo';
import { useTranslation } from 'react-i18next';
import { shortedName } from 'src/utils/UtilsFunc';

const FormLogin = () => {
  let { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ui = useSelector((state) => state.ui.ui);
  const { authenticated } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.user);
  const { theme } = ui;
  const mail = !!authenticated?.remember ? profile?.email : '';
  const [email, setEmail] = useState(mail);
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.auth.csrfToken();
      const aut = await api.auth.login(email, password);
      dispatch(
        setAuthenticated({
          ...authenticated,
          isLogged: true,
          logged_in: JSON.stringify(new Date()),
        }),
      );
      setAccountInfo(aut);
    } catch (error) {
      console.log('ERROR => ', error);
    }
  };

  const setAccountInfo = async (aut) => {
    try {
      dispatch(setProfile(aut));
      navigate('/dashboard');
    } catch (error) {
      console.log('GET ACCOUNT INFO ERROR => ', error);
    }
  };

  const handleRemember = (event) => {
    dispatch(setAuthenticated({ ...authenticated, remember: event.target.checked }));
    dispatch(setProfile({ ...profile, name: '', email: '' }));
  };

  return (
    <div>
      <CCol xs={12}></CCol>
      <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CAlert color="danger" dismissible visible={!!state?.logout}>
                <strong>{t('common.expired.title')}</strong>... {t('common.expired.subtitle')}
              </CAlert>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm onSubmit={handleSubmit}>
                      <h1>{t('common.appTitle')}</h1>
                      <p className="text-body-secondary">
                        {!!authenticated?.remember && profile?.name
                          ? `${t('loginScreen.welcome')} ${shortedName(profile?.name)}`
                          : t('loginScreen.title')}
                      </p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          type="email"
                          placeholder={t('loginScreen.email')}
                          autoComplete="email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          required
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type="password"
                          placeholder={t('loginScreen.password')}
                          autoComplete="current-password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          required
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CFormSwitch
                          label={t('loginScreen.remember')}
                          id="formSwitchCheckDefault"
                          checked={authenticated?.remember}
                          onChange={handleRemember}
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol xs={12}>
                          <div className="d-grid gap-2">
                            <CButton color="primary" className="px-4" type="submit">
                              {t('loginScreen.enter')}
                            </CButton>
                          </div>
                        </CCol>
                        <CCol xs={12} className="text-right pt-3">
                          <CButton color="link" className="px-0">
                            {t('loginScreen.forgot')}
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard className="text-white bg-primary py-4 text-center">
                  <CCardBody className="d-flex flex-column justify-content-evenly">
                    <CIcon
                      customClassName=""
                      icon={theme === 'dark' ? logo_light : logo_dark}
                      height={40}
                      alt="Criptou"
                      title="Criptou"
                    />
                    <Link
                      to="https://criptou.com.br"
                      target="_blank"
                      alt="Criptou"
                      title="Criptou"
                      className="mt-5"
                    >
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        {t('loginScreen.website')}
                      </CButton>
                    </Link>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  );
};

export default FormLogin;
