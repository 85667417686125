import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import FormLogin from '../views/login/FormLogin';
import ProtectedRoute from './ProtectedRoute';
import api from '../api';
import DefaultLayout from 'src/layout/DefaultLayout';
import { setAuthenticated } from 'src/reducers/AuthReducer';

const AppRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    api.onUnauthenticated = (data) => {
      if (data?.status === 401 && data?.statusText === 'Unauthorized') {
        dispatch(setAuthenticated({ ...authenticated, isLogged: false }));
        navigate('/login', { replace: true, state: { logout: true } });
      }
    };
  }, [authenticated, dispatch, navigate]);

  return (
    <div>
      <Routes>
        <Route index path="/" element={<FormLogin />} />
        <Route element={<ProtectedRoute isAllowed={!!authenticated?.isLogged} />}>
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Route>
        <Route path="/login" element={<FormLogin />} />
        <Route path="*" element={`<p>There's nothing here: 404!</p>`} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
