export const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, '');
};

export const shortedName = (text) => {
  if (!!text) {
    let [first_name] = text.split(' ');
    let initials = first_name;
    return initials;
  }
  return;
};
export const shortedNameInitials = (text) => {
  if (!!text) {
    let [first_name, last_name] = text.split(' ');
    let initials = first_name[0] + last_name[0];
    return initials;
  }
  return;
};

export const createStatusTextObject = (statuses, prefix) => {
  return Object.keys(statuses).reduce((acc, key) => {
    acc[key] = `${prefix} ${statuses[key]}`;
    return acc;
  }, {});
};

export const createStatusColorObject = (statuses, colors) => {
  return Object.keys(statuses).reduce((acc, key) => {
    acc[key] = colors[key];
    return acc;
  }, {});
};

export const cleanTheme = () => {
  window.localStorage.removeItem('coreui-free-react-admin-template-theme');
};

export const UtilsFunc = {
  camelize,
  shortedName,
  shortedNameInitials,
  createStatusTextObject,
  createStatusColorObject,
  cleanTheme,
};
