import BaseModule from './BaseModule.js';

class Withdrawal extends BaseModule {
  async paginate({ page, per_page, term }) {
    return await this.get(`/back-office/withdrawals`, { params: { page, per_page, term } });
  }

  async fetch(id) {
    return await this.get(`/back-office/withdrawals/${id}`);
  }
}

export default Withdrawal;
