import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CContainer, CDropdown, CHeader, CHeaderNav, CHeaderToggler } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilMenu } from '@coreui/icons';

import { AppBreadcrumb } from './index';
import { AppHeaderDropdown, LanguageDropdown, ThemeDropdown } from './header/index';
import { setUi } from 'src/reducers/LayoutReducer';

const AppHeader = () => {
  const headerRef = useRef();

  const dispatch = useDispatch();
  const stateUI = useSelector((state) => state.ui.ui);
  const { sidebarShow } = stateUI;

  useEffect(() => {
    document.addEventListener('scroll', () => {
      headerRef.current &&
        headerRef.current.classList.toggle('shadow-sm', document.documentElement.scrollTop > 0);
    });
  }, []);

  const handleMenu = () => {
    dispatch(setUi({ ...stateUI, sidebarShow: !sidebarShow }));
  };

  return (
    <CHeader position="sticky" className="mb-4 p-0" ref={headerRef}>
      <CContainer className="border-bottom px-4" fluid>
        <CHeaderToggler onClick={handleMenu} style={{ marginInlineStart: '-14px' }}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderNav className="ms-auto">
          <LanguageDropdown />
          <li className="nav-item py-1">
            <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
          </li>
        </CHeaderNav>
        <CHeaderNav className="">
          <CDropdown variant="nav-item" placement="bottom-end">
            <ThemeDropdown />
          </CDropdown>
        </CHeaderNav>
        <CHeaderNav>
          <li className="nav-item py-1">
            <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
          </li>
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CContainer className="px-4" fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
