import createReducer from '../store/createReducer';

const initial_state = {
  authenticated: {
    isLogged: false,
    access_token: '',
    token_type: '',
    expires_in: '',
    logged_in: '',
    last_login: '',
    remember: false,
  },
};

const [AuthReducer, actions] = createReducer('AUTH', initial_state);

export const setAuthenticated = actions.setAuthenticated;

export default AuthReducer;
