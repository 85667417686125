import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CDropdownItem, CDropdownMenu, CDropdownToggle, useColorModes } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilContrast, cilMoon, cilSun } from '@coreui/icons';
import { setUi } from 'src/reducers/LayoutReducer';
import { useTranslation } from 'react-i18next';

const ThemeDropdown = () => {
  const headerRef = useRef();
  const { colorMode, setColorMode } = useColorModes('coreui-theme');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateUI = useSelector((state) => state.ui.ui);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      headerRef.current &&
        headerRef.current.classList.toggle('shadow-sm', document.documentElement.scrollTop > 0);
    });
  }, []);

  const handleThemeMode = (selected) => {
    dispatch(setUi({ ...stateUI, theme: selected }));
    setColorMode(selected);
  };

  return (
    <>
      <CDropdownToggle caret={false}>
        {colorMode === 'dark' ? (
          <CIcon icon={cilMoon} size="lg" />
        ) : colorMode === 'auto' ? (
          <CIcon icon={cilContrast} size="lg" />
        ) : (
          <CIcon icon={cilSun} size="lg" />
        )}
      </CDropdownToggle>
      <CDropdownMenu>
        <CDropdownItem
          active={colorMode === 'light'}
          className="d-flex align-items-center"
          component="button"
          type="button"
          onClick={() => handleThemeMode('light')}
        >
          <CIcon className="me-2" icon={cilSun} size="lg" /> {t('theme.light')}
        </CDropdownItem>
        <CDropdownItem
          active={colorMode === 'dark'}
          className="d-flex align-items-center"
          component="button"
          type="button"
          onClick={() => handleThemeMode('dark')}
        >
          <CIcon className="me-2" icon={cilMoon} size="lg" /> {t('theme.dark')}
        </CDropdownItem>
        <CDropdownItem
          active={colorMode === 'auto'}
          className="d-flex align-items-center"
          component="button"
          type="button"
          onClick={() => handleThemeMode('auto')}
        >
          <CIcon className="me-2" icon={cilContrast} size="lg" /> {t('theme.auto')}
        </CDropdownItem>
      </CDropdownMenu>
    </>
  );
};

export default ThemeDropdown;
