import React, { useEffect, useState } from 'react';
import { CButton, CCol, CContainer, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilActionUndo } from '@coreui/icons';
import { useNavigate, useLocation } from 'react-router-dom';

const Page404 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [lastRoute, setLastRoute] = useState('');

  useEffect(() => {
    setLastRoute(location.pathname);
  }, [location]);

  const redirectToPreviousRoute = () => {
    if (lastRoute) {
      const parts = lastRoute.split('/'); // Dividir a string da rota em partes
      parts.pop(); // Remover a última parte da rota

      const previousRoute = parts.join('/'); // Reunir as partes para formar o caminho desejado

      navigate(previousRoute);
    }
  };

  return (
    <div className="bg-body-tertiary  d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <div className="clearfix">
              <h1 className="float-start display-3 me-4">403</h1>
              <h4 className="pt-3">Oops! Access denied.</h4>
              <p className="text-body-secondary float-start">
                You do not have permission to access this page or use this feature.
              </p>
              <div className="my-5">
                <CButton color="primary" onClick={redirectToPreviousRoute}>
                  <CIcon
                    icon={cilActionUndo}
                    size="sm"
                    style={{ color: 'white', rotate: '90deg', marginRight: 5 }}
                  />
                  Back
                </CButton>
              </div>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Page404;
