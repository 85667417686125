import { lazy } from 'react';

const Dashboard = lazy(() => import('../views/dashboard/Dashboard'));

//Orders
const ListOrders = lazy(() => import('../views/orders/ListOrders'));
const DetailOrder = lazy(() => import('../views/orders/DetailOrder'));

//Users
const ListUsers = lazy(() => import('../views/users/ListUsers'));
const FormUser = lazy(() => import('../views/users/FormUser'));

//Pix Cash Ins
const ListPixCashIns = lazy(() => import('../views/pix-cash-ins/ListPixCashIns'));
const DetailPixCashIn = lazy(() => import('../views/pix-cash-ins/DetailPixCashIn'));

//Currencies
const ListCurrencies = lazy(() => import('../views/currencies/ListCurrencies'));
const FormCurrency = lazy(() => import('../views/currencies/FormCurrency'));

//Customers
const ListCustomers = lazy(() => import('../views/customers/ListCustomers'));
const FormCustomer = lazy(() => import('../views/customers/FormCustomer'));
const DetailCustomer = lazy(() => import('../views/customers/DetailCustomer'));
const FormApiCredentials = lazy(() => import('../views/customers/FormApiCredentials'));

//Wallets
const ListWallets = lazy(() => import('../views/wallets/ListWallets'));
const FormWallet = lazy(() => import('../views/wallets/FormWallet'));
const DetailWallet = lazy(() => import('../views/wallets/DetailWallet'));

//Networks
const ListNetworks = lazy(() => import('../views/networks/ListNetworks'));
const FormNetwork = lazy(() => import('../views/networks/FormNetwork'));

//Payers
const ListPayers = lazy(() => import('../views/payers/ListPayers'));
const FormPayer = lazy(() => import('../views/payers/FormPayer'));
const DetailPayer = lazy(() => import('../views/payers/DetailPayer'));

//Tiers
const ListTiers = lazy(() => import('../views/tiers/ListTiers'));
const FormTier = lazy(() => import('../views/tiers/FormTier'));
const DetailTier = lazy(() => import('../views/tiers/DetailTier'));

//Withdrawals
const ListWithdrawals = lazy(() => import('../views/withdrawals/ListWithdrawals'));
const DetailWithdrawal = lazy(() => import('../views/withdrawals/DetailWithdrawal'));

const Page403 = lazy(() => import('../views/pages/Page403'));

const routes = [
  { path: '/', exact: true, name: 'Home' },
  {
    path: '/dashboard',
    name: 'Dashboard',
    element: Dashboard,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },

  {
    path: '/orders',
    name: 'Orders',
    element: ListOrders,
    exact: true,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/orders/list-orders',
    name: 'List',
    element: ListOrders,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/orders/details-order',
    name: 'Details',
    element: DetailOrder,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },

  { path: '/users', name: 'Users', element: ListUsers, exact: true, role: ['ADMIN', 'USER'] },
  { path: '/users/list-users', name: 'List', element: ListUsers, role: ['ADMIN', 'USER'] },
  { path: '/users/edit-user', name: 'Edit', element: FormUser, role: ['ADMIN'] },
  { path: '/users/add-user', name: 'New', element: FormUser, role: ['ADMIN'] },

  {
    path: '/pix-cash-ins',
    name: 'Pix Cash Ins',
    element: ListPixCashIns,
    exact: true,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/pix-cash-ins/list-pix-cash-ins',
    name: 'List',
    element: ListPixCashIns,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/pix-cash-ins/details-pix-cash-in',
    name: 'Details',
    element: DetailPixCashIn,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },

  {
    path: '/currencies',
    name: 'Currencies',
    element: ListCurrencies,
    exact: true,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/currencies/list-currencies',
    name: 'List',
    element: ListCurrencies,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  { path: '/currencies/edit-currency', name: 'Edit', element: FormCurrency, role: ['ADMIN'] },
  { path: '/currencies/add-currency', name: 'New', element: FormCurrency, role: ['ADMIN'] },

  {
    path: '/customers',
    name: 'Customers',
    element: ListCustomers,
    exact: true,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/customers/list-customers',
    name: 'List',
    element: ListCustomers,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  { path: '/customers/edit-customer', name: 'Edit', element: FormCustomer, role: ['ADMIN'] },
  {
    path: '/customers/details-customer',
    name: 'Details',
    element: DetailCustomer,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/customers/add-api-credential',
    name: 'Add API Credential',
    element: FormApiCredentials,
    role: ['ADMIN'],
  },

  {
    path: '/wallets',
    name: 'Wallets',
    element: ListWallets,
    exact: true,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/wallets/list-wallets',
    name: 'List',
    element: ListWallets,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  { path: '/wallets/edit-wallet', name: 'Edit', element: FormWallet, role: ['ADMIN'] },
  { path: '/wallets/add-wallet', name: 'New', element: FormWallet, role: ['ADMIN'] },
  {
    path: '/wallets/details-wallet',
    name: 'Details',
    element: DetailWallet,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },

  {
    path: '/networks',
    name: 'Networks',
    element: ListNetworks,
    exact: true,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/networks/list-networks',
    name: 'List',
    element: ListNetworks,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  { path: '/networks/edit-network', name: 'Edit', element: FormNetwork, role: ['ADMIN'] },
  { path: '/networks/add-network', name: 'New', element: FormNetwork, role: ['ADMIN'] },

  {
    path: '/payers',
    name: 'Payers',
    element: ListPayers,
    exact: true,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/payers/list-payers',
    name: 'List',
    element: ListPayers,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  { path: '/payers/edit-payer', name: 'Edit', element: FormPayer, role: ['ADMIN'] },
  { path: '/payers/details-payer', name: 'Details', element: DetailPayer, role: ['ADMIN'] },

  {
    path: '/tiers',
    name: 'Tiers',
    element: ListTiers,
    exact: true,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/tiers/list-tiers',
    name: 'List',
    element: ListTiers,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  { path: '/tiers/edit-tier', name: 'Edit', element: FormTier, role: ['ADMIN'] },
  { path: '/tiers/add-tier', name: 'New', element: FormTier, role: ['ADMIN'] },
  { path: '/tiers/details-tier', name: 'Details', element: DetailTier, role: ['ADMIN'] },

  {
    path: '/withdrawals',
    name: 'Withdrawals',
    element: ListWithdrawals,
    exact: true,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/withdrawals/list-withdrawals',
    name: 'List',
    element: ListWithdrawals,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },
  {
    path: '/withdrawals/details-withdrawal',
    name: 'List',
    element: DetailWithdrawal,
    role: ['ADMIN', 'USER', 'ANALYZE'],
  },

  { path: '/access-denied', name: 'Access Denied', element: Page403, exact: true },
];

export default routes;
