import BaseModule from './BaseModule.js';

class Payer extends BaseModule {
  async paginate({ page, per_page, term }) {
    return await this.get(`back-office/payers`, { params: { page, per_page, term } });
  }

  async update({ id, status = 'BLOCKED' }) {
    return await this.patch(`back-office/payers/${id}`, {
      status: status,
    });
  }

  async fetch(id) {
    return await this.get(`back-office/payers/${id}`);
  }
}

export default Payer;
