import createReducer from '../store/createReducer';

const initial_state = {
  profile: {
    id: null,
    name: '',
    email: '',
    role: [''],
    status: '',
    created_at: '',
    updated_at: '',
  },
};

const [UserReducer, actions] = createReducer('USER', initial_state);

export const setProfile = actions.setProfile;

export default UserReducer;
