import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cifBr, cifEs, cifUs } from '@coreui/icons';
import { setUi } from 'src/reducers/LayoutReducer';
import { useTranslation } from 'react-i18next';

const LanguageDropdown = () => {
  const headerRef = useRef();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const stateUI = useSelector((state) => state.ui.ui);
  const { language } = stateUI;

  useEffect(() => {
    document.addEventListener('scroll', () => {
      headerRef.current &&
        headerRef.current.classList.toggle('shadow-sm', document.documentElement.scrollTop > 0);
    });
  }, []);

  const handleLanguage = (selected) => {
    i18n.changeLanguage(selected);
    i18n.reloadResources(selected);
    dispatch(setUi({ ...stateUI, language: selected }));
  };

  return (
    <CDropdown dark component="li" variant="nav-item">
      <CDropdownToggle caret={false}>
        {language === 'pt_br' ? (
          <CIcon icon={cifBr} size="lg" />
        ) : language === 'en' ? (
          <CIcon icon={cifUs} size="lg" />
        ) : (
          <CIcon icon={cifEs} size="lg" />
        )}
      </CDropdownToggle>
      <CDropdownMenu>
        <CDropdownItem
          active={language === 'en'}
          className="d-flex align-items-center"
          component="button"
          type="button"
          onClick={() => handleLanguage('en')}
        >
          <CIcon className="me-2" icon={cifUs} size="lg" /> {t('language.en')}
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem
          active={language === 'pt_br'}
          className="d-flex align-items-center"
          component="button"
          type="button"
          onClick={() => handleLanguage('pt_br')}
        >
          <CIcon className="me-2" icon={cifBr} size="lg" /> {t('language.pt_br')}
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem
          active={language === 'es'}
          className="d-flex align-items-center"
          component="button"
          type="button"
          onClick={() => handleLanguage('es')}
        >
          <CIcon className="me-2" icon={cifEs} size="lg" /> {t('language.es')}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default LanguageDropdown;
