import EN from './translates/en.json';
import PTBR from './translates/pt_br.json';
import ES from './translates/es.json';

export const resources = {
  en: {
    translation: EN,
  },
  pt_br: {
    translation: PTBR,
  },
  es: {
    translation: ES,
  },
};

export const languages = [
  { code: 'en', label: 'English' },
  { code: 'pt_br', label: 'Português Brasil' },
  { code: 'es', label: 'Espanhol' },
];
